// pages/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import './Register.css';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Hook para redirecionar

  const handleRegister = async (e) => {
    e.preventDefault();

    // Validações de front-end antes do envio
    const trimmedUsername = username.trim().toLowerCase();
    const trimmedEmail = email.trim().toLowerCase();

    // Verificar se o username ou email contém espaços
    if (trimmedUsername.includes(' ') || trimmedEmail.includes(' ')) {
      setMessage('O nome de usuário e o e-mail não podem conter espaços.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, { 
        username: trimmedUsername, 
        email: trimmedEmail, 
        password 
      });

      setMessage('Cadastro realizado com sucesso! Redirecionando para a página de login...');

      // Redirecionar para a página de login após 2 segundos
      setTimeout(() => {
        navigate('/login');
      }, 2000);

      console.log(process.env.REACT_APP_API_BASE_URL);
    } catch (error) {
      // Verifica se a resposta do servidor contém um erro
      if (error.response && error.response.data) {
        setMessage(error.response.data.error);
      } else {
        setMessage('Ocorreu um erro inesperado.');
      }
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <h2>Registrar-se</h2>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="username">Nome de Usuário</label>
            <input 
              type="text" 
              id="username" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              placeholder="Digite o nome de usuário" 
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Digite o e-mail" 
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Senha</label>
            <input 
              type="password" 
              id="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Digite a senha" 
            />
          </div>
          <button type="submit" className="register-button">Registrar</button>
        </form>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Register;
