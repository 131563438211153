import React from 'react';
import './AdminDashboard.css';

const AdminSettings = () => {
  return (
    <div className="admin-page">
      <h2>Configurações do Administrador</h2>
      <p>Esta é a página de configurações do administrador. Aqui você pode ajustar as configurações da plataforma.</p>
      {/* Adicione aqui a lógica para gerenciar as configurações do administrador */}
    </div>
  );
};

export default AdminSettings;