import React from 'react';

const Support = () => {
  return (
    <div className="support-container">
      <h2>Suporte</h2>
      <form>
        {/* Formulário para solicitação de suporte */}
        <div className="form-group">
          <label>Sua Mensagem:</label>
          <textarea placeholder="Digite sua mensagem aqui"></textarea>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default Support;