// components/StepsSection.js
import React from 'react';
import './StepsSection.css';

// Importar os ícones
import Icon4 from './img/icon-4.png'; // Atualize o caminho conforme necessário
import Icon5 from './img/icon-5.png';
import Icon6 from './img/icon-6.png';

const steps = [
  { title: 'Crie uma conta', icon: Icon4 },
  { title: 'Escolha o criptoativo', icon: Icon5 },
  { title: 'Pague via Pix', icon: Icon6 },
  { title: 'Receba seu token', icon: Icon4 }, // Usando Icon4 novamente como exemplo
];

const StepsSection = () => {
  return (
    <section className="steps-section">
      <div className="steps-container">
        {steps.map((step, index) => (
          <div className="step-card" key={index}>
            <div className="step-icon">
              <img src={step.icon} alt={`Step ${index + 1} icon`} />
            </div>
            <h3>{step.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StepsSection;
