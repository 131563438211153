import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './OrderManagement.css';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/orders`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        setError('Erro ao buscar pedidos.');
        console.error('Erro ao buscar pedidos:', error);
      }
    };

    fetchOrders();
  }, []);

  // Função para truncar texto com um limite máximo de caracteres
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <div className="order-management-container">
      <h2>Gerenciar Pedidos</h2>
      {error && <p className="error-message">{error}</p>}
      <table className="order-table">
        <thead>
          <tr>
            <th>Pedido ID</th>
            <th>Usuário</th>
            <th>Status</th>
            <th>Valor</th>
            <th>Token</th>
            <th>Quantidade</th>
            <th>Preço Unitário</th>
            <th>Carteira</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order._id}>
              <td>{truncateText(order._id, 5)}</td>
              <td>{truncateText(order.user?.username, 20)}</td>
              <td>{order.status}</td>
              <td>R$ {order.valor}</td>
              <td>{order.tokenSelecionado}</td>
              <td>{order.quantidadeTokensSelecionado}</td>
              <td>R$ {order.valor_unidade}</td>
              <td>{truncateText(order.wallet, 20)}</td>
              <td>
                <button className="view-details-button">
                  <Link to={`/admin/orders/${order._id}`}>{truncateText('Detalhes', 10)}</Link>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
