// components/HeroSection.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import './HeroSection.css';
import HeroImage from './img/hero-img.png'; // Importando a imagem

const HeroSection = () => {
  const navigate = useNavigate(); // Hook para redirecionamento

  const handleRegisterClick = () => {
    navigate('/register'); // Redireciona para a página de registro
  };

  return (
    <section className="hero-section" id="home">
      <div className="hero-content">
        <h1>Invista em criptomoedas<br />A maneira mais fácil de negociar Bitcoin e outras criptos.</h1>
        <p>Inicie sua transação agora e aproveite negocie criptomoedas com PIX.</p>
        <button className="cta-button" onClick={handleRegisterClick}>CADASTRE-SE</button>
      </div>
      <div className="hero-image">
        <img src={HeroImage} alt="Cryptocurrency illustration" />
      </div>
    </section>
  );
};

export default HeroSection;
