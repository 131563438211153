import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentStatus.css';
import { QRCodeCanvas } from 'qrcode.react';
import { jwtDecode } from 'jwt-decode';

const PaymentStatus = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const token = localStorage.getItem('token'); // Pegando o token do usuário armazenado

      if (!token) {
        setError('Usuário não autenticado. Por favor, faça login.');
        setLoading(false);
        return;
      }

      try {
        // Decodifica o token JWT para pegar o userId
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id; // Obtém o ID do usuário

        // Faz a chamada para a API, passando o userId como parâmetro na URL
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/payments/status/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setPayments(response.data);
        setLoading(false);
      } catch (err) {
        setError('Erro ao carregar o status dos pagamentos.');
        setLoading(false);
      }
    };

    fetchPaymentStatus();
  }, []);

  return (
    <div className="payment-status-container">
      <h2>Status do Pagamento</h2>
      <p>Acompanhe o status dos seus pagamentos e transferências.</p>

      {loading && <p>Carregando...</p>}
      {error && <p className="error-message">{error}</p>}
      
      <div className="payments-list">
        {payments.length === 0 && !loading && !error && (
          <p>Não foram encontrados pagamentos.</p>
        )}

        {payments.slice().reverse().map((payment, index) => (
          <div key={payment.txid} className="payment-item">
            <h3>Ordem: {index + 1}</h3>
            <p>Status: <span className={`status ${payment.status.toLowerCase()}`}>{payment.status}</span></p>
            <p>Valor: R$ {payment.valor.original}</p>
            <p>Data: {new Date(payment.calendario.criacao).toLocaleString()}</p>

            {/* Exibe o QR Code se o status for "ATIVA" */}
            {payment.status === 'ATIVA' && (
              <div className="qr-code">
                <p>Escaneie o QR Code para pagar:</p>
                <QRCodeCanvas 
                  value={payment.brcode} 
                  size={200}
                  level={"L"} 
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentStatus;
