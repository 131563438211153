import React, { useState } from 'react';
import './AccountSettings.css'; // Certifique-se de incluir o CSS para estilização
import axios from 'axios'; // Importar axios
import InputMask from 'react-input-mask'; // Importar a biblioteca de máscaras

const AccountSettings = () => {
  // States para os dados do formulário
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [chavePix, setChavePix] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cpf, setCpf] = useState(''); 
  const [rg, setRg] = useState(''); 

  // Função para remover caracteres não numéricos
  const removeMask = (value) => {
    return value.replace(/\D/g, ''); // Remove tudo que não é número
  };

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Remover as máscaras antes de enviar os dados
    const formData = {
      nome,
      email,
      telefone: removeMask(telefone), // Remove a máscara
      chavePix,
      dataNascimento,
      cpf: removeMask(cpf), // Remove a máscara
      rg: removeMask(rg), // Remove a máscara
    };

    try {
      const token = localStorage.getItem('token'); // Supondo que o token está armazenado no localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/upload-documents`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`, // Adiciona o token aos headers
          'Content-Type': 'application/json',
        }
      });

      console.log('Dados enviados:', response.data);
      alert('Dados enviados com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar os dados:', error.response || error);
      alert('Erro ao enviar os dados.');
    }
  };

  return (
    <div className="account-settings-container">
      <h2>Configurações da Conta</h2>
      <form onSubmit={handleSubmit}>
        {/* Campo de Nome */}
        <div className="form-group">
          <label>Nome Completo:</label>
          <input 
            type="text" 
            placeholder="Seu nome completo" 
            value={nome} 
            onChange={(e) => setNome(e.target.value)} 
          />
        </div>

        {/* Campo de Email */}
        <div className="form-group">
          <label>Email:</label>
          <input 
            type="email" 
            placeholder="Seu email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>

        {/* Campo de Telefone com Máscara */}
        <div className="form-group">
          <label>Telefone:</label>
          <InputMask 
            mask="(99) 99999-9999" 
            placeholder="Seu telefone" 
            value={telefone} 
            onChange={(e) => setTelefone(e.target.value)} 
          />
        </div>

        {/* Campo de CPF com Máscara */}
        <div className="form-group">
          <label>CPF:</label>
          <InputMask 
            mask="999.999.999-99" 
            placeholder="Seu CPF" 
            value={cpf} 
            onChange={(e) => setCpf(e.target.value)} 
          />
        </div>

        {/* Campo de RG com Máscara */}
        <div className="form-group">
          <label>RG:</label>
          <InputMask 
            mask="99.999.999-9" 
            placeholder="Seu RG" 
            value={rg} 
            onChange={(e) => setRg(e.target.value)} 
          />
        </div>

        {/* Campo de Chave Pix */}
        <div className="form-group">
          <label>Chave Pix:</label>
          <input 
            type="text" 
            placeholder="Sua chave Pix" 
            value={chavePix} 
            onChange={(e) => setChavePix(e.target.value)} 
          />
        </div>

        {/* Campo de Data de Nascimento */}
        <div className="form-group">
          <label>Data de Nascimento:</label>
          <input 
            type="date" 
            value={dataNascimento} 
            onChange={(e) => setDataNascimento(e.target.value)} 
          />
        </div>

        {/* Botão de Salvar */}
        <button type="submit">Salvar</button>
      </form>
    </div>
  );
};

export default AccountSettings;
