import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import getTokenPriceFromGeckoTerminal from '../../services/tokenPriceService';
import './BuyCrypto.css'; 
import { QRCodeCanvas } from 'qrcode.react';
import { jwtDecode } from 'jwt-decode';

const BuyCrypto = () => {
  const [amount, setAmount] = useState('');
  const [crypto, setCrypto] = useState('Bitcoin');
  const [cryptoAmount, setCryptoAmount] = useState('');
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [brcode, setBrcode] = useState('');
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState('');
  const [userData, setUserData] = useState({}); // Estado para armazenar os dados do usuário

  // Função para copiar o conteúdo do Pix Copia e Cola
  const copyToClipboard = () => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(brcode).then(() => {
      alert('Código Pix Copia e Cola copiado!');
    }).catch(err => {
      alert('Falha ao copiar. Por favor, tente novamente.');
      console.error('Erro ao copiar para a área de transferência: ', err);
    });
  } else {
    alert('A função de copiar não é suportada neste navegador.');
  }
};

  // Função para buscar os dados do usuário
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;
      
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao buscar os dados do usuário:', error);
    }
  };

  // Função para buscar os preços das criptomoedas
  const fetchCryptoPrices = useCallback(async () => {
    setIsLoading(true);
    try {
      const prices = {};
      const enunPriceData = await getTokenPriceFromGeckoTerminal();
      prices['ENUN'] = enunPriceData ? parseFloat(enunPriceData.tokenPrice) * 5 : null;

      const response = await axios.get(
        'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum&vs_currencies=brl'
      );
      prices['Bitcoin'] = response.data.bitcoin?.brl || null;
      prices['Ethereum'] = response.data.ethereum?.brl || null;

      setCryptoPrices(prices);
    } catch (error) {
      console.error('Erro ao buscar os preços das criptomoedas:', error);
      setMessage('Erro ao buscar os preços das criptomoedas.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Função para calcular a quantidade de criptomoeda que pode ser comprada
  const calculateCryptoAmount = useCallback((amount, price) => {
    if (price && !isNaN(price) && amount && !isNaN(amount)) {
      const calculatedAmount = (amount / price).toFixed(8);
      setCryptoAmount(calculatedAmount);
    } else {
      setCryptoAmount('0');
    }
  }, []);

  // Função para lidar com a mudança no valor em reais
  const handleAmountChange = (e) => {
    let newAmount = e.target.value;
    newAmount = newAmount.replace(/\D/g, '');
    newAmount = (newAmount / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    setAmount(newAmount);

    const numericValue = parseFloat(newAmount.replace(/[R$.\s]/g, '').replace(',', '.'));
    calculateCryptoAmount(numericValue, cryptoPrices[crypto]);
  };

  const handleCryptoChange = (e) => {
    const newCrypto = e.target.value;
    setCrypto(newCrypto);
    const numericValue = parseFloat(amount.replace(/[R$.\s]/g, '').replace(',', '.'));
    calculateCryptoAmount(numericValue, cryptoPrices[newCrypto]);
  };

  // Função para buscar as carteiras do usuário
  const fetchWallets = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/wallets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWallets(response.data);
    } catch (error) {
      console.error('Erro ao buscar carteiras:', error);
    }
  };

  // Função para lidar com a compra
  const handleBuy = async (e) => {
    e.preventDefault();
    try {
      if (!selectedWallet) {
        setMessage('Por favor, selecione uma carteira antes de prosseguir.');
        return;
      }

      // Verificar se os dados do usuário estão preenchidos
      if (!userData.cpf || !userData.nome) {
        setMessage('Por favor, complete seu perfil com CPF e Nome antes de realizar uma compra.');
        return;
      }

      const numericAmount = parseFloat(amount.replace(/[R$.\s]/g, '').replace(',', '.'));

      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.id;

      // Dados da transação
      const transactionData = {
        valor: numericAmount,
        descricao: `Compra de ${cryptoAmount} ${crypto}`,
        cpf: userData.cpf,
        cnpj: '',
        nomeDevedor: userData.nome,
      };

      console.log('Dados da transação:', transactionData); // Log dos dados da transação

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/pix-payment`, transactionData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setBrcode(response.data.brcode);

      // Log da resposta do pagamento Pix
      console.log('Resposta do pagamento Pix:', response.data);

      // Criação da ordem, agora com os novos campos
      const orderData = {
        userId: userId,
        txid: response.data.txid,
        valor: numericAmount,
        status: 'ATIVA',
        quantidadeTokensSelecionado: cryptoAmount,
        valor_unidade: cryptoPrices[crypto],
        tokenSelecionado: crypto,
        wallet: selectedWallet,
      };

      console.log('Dados da ordem:', orderData); // Log dos dados da ordem

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/orders`, {
          userId: userId,
          txid: response.data.txid,
          valor: numericAmount,
          status: 'ATIVA',
          quantidadeTokensSelecionado: cryptoAmount,
          valor_unidade: cryptoPrices[crypto],
          tokenSelecionado: crypto,
          wallet: selectedWallet,
          hash: 'SUA_HASH_AQUI', // Inclua a hash durante a criação da ordem
      }, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });


      setMessage('Realize o pagamento utilizando o QRCode abaixo.');
    } catch (error) {
      console.error('Erro ao completar a transação:', error);
      if (error.response) {
        console.error('Detalhes do erro:', error.response.data);
      }
      setMessage('Falha ao completar a transação. Tente novamente.');
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchCryptoPrices();
    fetchWallets();

    const interval = setInterval(() => {
      fetchCryptoPrices();
    }, 300000);

    return () => clearInterval(interval);
  }, [fetchCryptoPrices]);

  useEffect(() => {
    const numericValue = parseFloat(amount.replace(/[R$.\s]/g, '').replace(',', '.'));
    calculateCryptoAmount(numericValue, cryptoPrices[crypto]);
  }, [amount, crypto, cryptoPrices, calculateCryptoAmount]);

  return (
    <div className="buy-crypto-container">
      <h2>Comprar Criptomoedas</h2>

      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <form onSubmit={handleBuy}>
          <div className="form-group">
            <label>Selecione a Criptomoeda:</label>
            <select value={crypto} onChange={handleCryptoChange}>
              <option value="Bitcoin">Bitcoin</option>
              <option value="Ethereum">Ethereum</option>
              <option value="ENUN">Enjoy Union (ENUN)</option>
            </select>
          </div>
          <div className="form-group">
            <label>Quantidade em BRL:</label>
            <input
              type="text"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Valor em reais"
            />
          </div>

          <div className="form-group">
            <label>Selecione a Carteira:</label>
            <select value={selectedWallet} onChange={(e) => setSelectedWallet(e.target.value)} required>
              <option value="" disabled>Selecione uma carteira</option>
              {wallets.map(wallet => (
                <option key={wallet._id} value={wallet.address}>
                  {wallet.network}: {wallet.address}
                </option>
              ))}
            </select>
          </div>

          {cryptoPrices[crypto] !== undefined && (
            <p>Preço unitário: R$ {cryptoPrices[crypto]?.toFixed(8)}</p>
          )}
          {cryptoAmount !== '' && (
            <p>Você poderá comprar aproximadamente {cryptoAmount} {crypto}.</p>
          )}
          <button type="submit">Comprar</button>
        </form>
      )}

      {message && <p>{message}</p>}

  {/* Exibir o conteúdo do Pix Copia e Cola e botão para copiar */}
  {brcode && (
    <div className="pix-copy-container">
      <p>Pix Copia e Cola:</p>
      <div className="pix-copy-code" style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          value={brcode}
          readOnly
          className="pix-code-input"
          style={{ flexGrow: 1, marginRight: '10px' }}
        />
        <button 
          onClick={copyToClipboard} 
          style={{ padding: '5px 10px', fontSize: '0.9rem', cursor: 'pointer' }}>
          Copiar
        </button>
      </div>
    </div>
  )}


      {/* Exibir o QR Code */}
      {brcode && <QRCodeCanvas value={brcode} />} {/* Exibe o QR Code */}
    </div>
  );
};

export default BuyCrypto;
