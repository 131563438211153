import React from 'react';
import './AdminDashboard.css';

const TokenManagement = () => {
  return (
    <div className="admin-page">
      <h2>Gerenciamento de Tokens</h2>
      <p>Esta é a página de gerenciamento de tokens. Aqui você pode adicionar, editar e excluir tokens.</p>
      {/* Adicione aqui a lógica para listar e gerenciar tokens */}
    </div>
  );
};

export default TokenManagement;