import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remover o token do localStorage e redirecionar para a página de login
    localStorage.removeItem('token');
    navigate('/login');
  }, [navigate]);

  return (
    <div className="logout-container">
      <h2>Saindo...</h2>
    </div>
  );
};

export default Logout;