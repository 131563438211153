import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './TransactionDetails.css'; // Estilização para os detalhes da transação

const TransactionDetails = () => {
  const { transactionId } = useParams(); // Obtém o `transactionId` dos parâmetros da URL
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/transactions/${transactionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTransaction(response.data);
      } catch (error) {
        setError('Erro ao buscar os detalhes da transação.');
        console.error('Erro ao buscar os detalhes da transação:', error);
      }
    };

    fetchTransactionDetails();
  }, [transactionId]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!transaction) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="transaction-details-container">
      <h2>Detalhes da Transação</h2>
      <p><strong>Transação ID:</strong> {transaction._id}</p>
      <p><strong>Status:</strong> {transaction.status}</p>
      <p><strong>Valor:</strong> R$ {transaction.valor}</p>
      <p><strong>Token:</strong> {transaction.tokenSelecionado}</p>
      <p><strong>Quantidade:</strong> {transaction.quantidadeTokensSelecionado}</p>
      <p><strong>Preço Unitário:</strong> R$ {transaction.valor_unidade}</p>
      <p><strong>Carteira:</strong> {transaction.wallet}</p>
      <p><strong>Hash da Transação:</strong> {transaction.hash ? transaction.hash : 'Não disponível'}</p>
    </div>
  );
};

export default TransactionDetails;
