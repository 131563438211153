// components/DownloadSection.js
import React from 'react';
import './DownloadSection.css';

const DownloadSection = () => {
  return (
    <section className="download-section" id="download">

    </section>
  );
};

export default DownloadSection;