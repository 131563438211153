import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate();

  // Função para encerrar a sessão
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token do localStorage
    navigate('/'); // Redireciona para a página inicial ou de login
  };

  return (
    <div className="admin-dashboard-container">
      <h2>Admin Dashboard</h2>
      <nav className="admin-dashboard-nav">
        <ul>
          <li>
            <Link to="/admin/users">Gerenciar Usuários</Link>
          </li>
          <li>
            <Link to="/admin/orders">Gerenciar Pedidos</Link>
          </li>
          <li>
            <Link to="/admin/reports">Relatórios</Link>
          </li>
          <li>
            <Link to="/admin/settings">Configurações</Link>
          </li>
          <li>
            <button onClick={handleLogout} className="logout-button">Sair</button>
          </li>
        </ul>
      </nav>
      <div className="admin-dashboard-content">
        {/* O Outlet renderiza o conteúdo da rota aninhada correspondente */}
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;
