// pages/Home.js
import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import FeaturesSection from '../components/FeaturesSection';
import StepsSection from '../components/StepsSection';
import DownloadSection from '../components/DownloadSection';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <ServicesSection />
      <FeaturesSection />
      <StepsSection />
      <DownloadSection />
      <Footer />
    </div>
  );
};

export default Home;
