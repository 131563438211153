import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserManagement.css'; // Importe o arquivo CSS

const UserManagement = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token'); // Pega o token do usuário logado
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/user`, {
          headers: {
            Authorization: `Bearer ${token}`, // Inclui o token na requisição
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div className="user-management-container">
      <h2>Gerenciar Usuários</h2>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Data de Cadastro</th>

          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{new Date(user.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
