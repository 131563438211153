import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './../pages/Dashboard.css'; // Importe o arquivo CSS com os estilos padrão

const DashboardLayout = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Alterna entre abrir e fechar o menu
  };

  return (
    <div className="dashboard-container">
      <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        {/* O conteúdo do botão será gerido pela classe CSS */}
        ☰
      </button>

      <nav className={`dashboard-nav ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/dashboard/buy-crypto" onClick={toggleMenu}>Comprar Criptomoedas</Link></li>
          <li><Link to="/dashboard/wallet" onClick={toggleMenu}>Gerenciar Carteira</Link></li>
          <li><Link to="/dashboard/transaction-history" onClick={toggleMenu}>Histórico de Transações</Link></li>
          <li><Link to="/dashboard/payment-status" onClick={toggleMenu}>Status do Pagamento</Link></li>
          <li><Link to="/dashboard/account-settings" onClick={toggleMenu}>Configurações da Conta</Link></li>
          <li><Link to="/dashboard/logout" onClick={toggleMenu}>Sair</Link></li>
        </ul>
      </nav>

      <div className="dashboard-content">
        <Outlet /> {/* Renderiza as páginas internas do dashboard */}
      </div>
    </div>
  );
};

export default DashboardLayout;
