// src/components/dashboard/Wallet.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Wallet.css'; // Importa o arquivo CSS

const Wallet = () => {
  const [network, setNetwork] = useState('');
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [wallets, setWallets] = useState([]);

  // Função para adicionar a carteira
  const addWallet = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/wallets`,
        { network, address },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage('Carteira cadastrada com sucesso!');
      setWallets([...wallets, response.data]); // Atualiza a lista de carteiras
    } catch (error) {
      // Verifica se a resposta contém os dados do erro e ajusta a exibição da mensagem
      if (error.response) {
        if (error.response.status === 400 && error.response.data === 'Carteira já cadastrada') {
          setMessage('Carteira já cadastrada');
        } else if (error.response.data && error.response.data.error) {
          setMessage(`Erro ao cadastrar a carteira: ${error.response.data.error}`);
        } else {
          setMessage('Erro ao cadastrar a carteira.');
        }
      } else {
        setMessage('Erro ao cadastrar a carteira.');
      }
    }
  };

  // Função para buscar as carteiras cadastradas
  const fetchWallets = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/wallets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWallets(response.data);
    } catch (error) {
      console.error('Erro ao buscar as carteiras:', error);
    }
  };

  // Função para deletar uma carteira
  const deleteWallet = async (walletId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/wallets/${walletId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setWallets(wallets.filter(wallet => wallet._id !== walletId)); // Remove a carteira da lista
      } else {
        console.error('Erro ao deletar a carteira:', response.data.error);
      }
    } catch (error) {
      console.error('Erro ao deletar a carteira:', error);
    }
  };

  // Buscar as carteiras cadastradas ao carregar o componente
  useEffect(() => {
    fetchWallets();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    addWallet();
  };

  return (
    <div className="wallet-container">
      <h2>Cadastrar Nova Carteira</h2>
      {/* Contêiner do formulário */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Rede:</label>
          <select
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            required
          >
            <option value="" disabled>Selecione a rede</option>
            <option value="BEP20">BEP20</option>
            <option value="BTC">BTC</option>
            <option value="ETH">ETH</option>
          </select>
        </div>
        <div>
          <label>Endereço da Carteira:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <button type="submit">Cadastrar Carteira</button>
      </form>
      {message && <p>{message}</p>}

      {/* Novo contêiner para a lista de carteiras */}
      {/* Novo contêiner para a lista de carteiras */}
      <div className="wallet-list-container">
        <h2>Carteiras Cadastradas</h2>
        <div className="wallet-list">
          {wallets.length > 0 ? (
            wallets.map(wallet => (
              <div key={wallet._id} className="wallet-item">
                <div className="wallet-info">
                  <span className="wallet-network">{wallet.network}:</span>
                  <span className="wallet-address">{wallet.address}</span>
                </div>
                <button
                  className="delete-button"
                  onClick={() => deleteWallet(wallet._id)}
                >
                  Deletar
                </button>
              </div>
            ))
          ) : (
            <p>Nenhuma carteira cadastrada.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Wallet;
