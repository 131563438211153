// components/FeaturesSection.js
import React from 'react';
import './FeaturesSection.css';
import ContentImage from './img/content-1.png';

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <div className="features-content">
        <h2>Nós somos uma plataforma de P2P</h2>
        <p>Nossa plataforma foi criada para oferecer uma experiência segura e eficiente. Conheça nossos serviços e entenda como podemos facilitar suas transações.</p>
        <ul>
          <li>Faça compra de criptoativos</li>
          <li>Muito rápido e confiável</li>
          <li>Em breve novos criptoativos serão adicionados</li>
        </ul>
      </div>
      <div className="features-image">
        <img src={ContentImage} alt="Cryptocurrency illustration" />
      </div>
    </section>
  );
};

export default FeaturesSection;
