import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './OrderDetails.css'; // Adicione um arquivo CSS para estilização, se desejar

const OrderDetails = () => {
  const { orderId } = useParams(); // Obtém o `orderId` dos parâmetros da URL
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('');
  const [hash, setHash] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/orders/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrder(response.data);
        setStatus(response.data.status);
        setHash(response.data.hash || '');
      } catch (error) {
        setError('Erro ao buscar os detalhes do pedido.');
        console.error('Erro ao buscar os detalhes do pedido:', error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleUpdateOrder = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/orders/${orderId}`, {
        status,
        hash,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate('/admin/orders'); // Redireciona para a página de gerenciamento de pedidos
    } catch (error) {
      setError('Erro ao atualizar o pedido.');
      console.error('Erro ao atualizar o pedido:', error);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!order) {
    return <p>Carregando...</p>;
  }

  return (
    <div className="order-details-container">
      <h2>Detalhes do Pedido</h2>
      <p><strong>Pedido ID:</strong> {order._id}</p>
      <p><strong>Usuário:</strong> {order.user?.username}</p>
      <p><strong>Status:</strong> {order.status}</p>
      <p><strong>Valor:</strong> R$ {order.valor}</p>
      <p><strong>Token:</strong> {order.tokenSelecionado}</p>
      <p><strong>Quantidade:</strong> {order.quantidadeTokensSelecionado}</p>
      <p><strong>Preço Unitário:</strong> R$ {order.valor_unidade}</p>
      <p><strong>Carteira:</strong> {order.wallet}</p>
      
      {/* Inputs para atualizar status e hash */}
      <div className="form-group">
        <label>Atualizar Status:</label>
        <select value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="ATIVA">ATIVA</option>
          <option value="COMPLETA">COMPLETA</option>
          <option value="CANCELADA">CANCELADA</option>
        </select>
      </div>
      <div className="form-group">
        <label>Hash de Transação:</label>
        <input
          type="text"
          value={hash}
          onChange={(e) => setHash(e.target.value)}
          placeholder="Insira a hash da transação"
        />
      </div>
      <button onClick={handleUpdateOrder}>Atualizar Pedido</button>
    </div>
  );
};

export default OrderDetails;
