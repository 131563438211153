// components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">P2PON</div>
      <nav className="nav-menu">
      </nav>
      <Link to="/login" className="signup-button">Entrar</Link>
    </header>
  );
};

export default Header;
