import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 P2Pon. Todos os direitos reservados.</p>
        <div className="footer-links">
          <a href="#about">Sobre nós</a>
          <a href="#services">Nossos Serviços</a>
        </div>
        <div className="contact-info">
          <p>Email: info@p2pon.online</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;