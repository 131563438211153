import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  
useEffect(() => {
  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsAuthenticated(false);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsAuthenticated(true);
      setIsAdmin(response.data.isAdmin);
    } catch (error) {
      console.error('Erro ao verificar a autenticação:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  checkAuth();
}, []);


  if (loading) {
    return <div>Carregando...</div>; // Mostrar carregamento enquanto verifica
  }

  if (!isAuthenticated || (adminOnly && !isAdmin)) {
    // Redirecionar para login se não estiver autenticado ou não for admin
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
