import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Reports.css';

const Reports = () => {
  const [reportData, setReportData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/reports`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setReportData(response.data);
      } catch (error) {
        setError('Erro ao buscar dados do relatório.');
        console.error('Erro ao buscar dados do relatório:', error);
      }
    };

    fetchReports();
  }, []);

  return (
    <div className="reports-container">
      <h2>Relatórios</h2>
      {error && <p className="error-message">{error}</p>}
      {!error && (
        <>
          <p>Total de Usuários: {reportData.totalUsers}</p>
          <p>Total de Compras: {reportData.totalOrders}</p>
          <p>Total de Compras Concluídas: {reportData.totalCompletedOrders}</p>
          <h3>Total de Compras por Token:</h3>
          <ul>
            {reportData.ordersByToken && reportData.ordersByToken.map((token) => (
              <li key={token._id}>
                {token._id}: {token.total} compras
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Reports;
