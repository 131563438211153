import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ProtectedRoute from './components/ProtectedRoute';
import DashboardLayout from './components/DashboardLayout';
import AdminDashboardLayout from './components/AdminDashboard';

// Importar as páginas do dashboard do usuário
import BuyCrypto from './components/dashboard/BuyCrypto';
import Wallet from './components/dashboard/Wallet';
import TransactionHistory from './components/dashboard/TransactionHistory';
import PaymentStatus from './components/dashboard/PaymentStatus';
import Support from './components/dashboard/Support';
import AccountSettings from './components/dashboard/AccountSettings';
import Logout from './components/dashboard/Logout';
import TransactionDetails from './components/dashboard/TransactionDetails';

// Importar as páginas do dashboard do administrador
import UserManagement from './components/admin/UserManagement';
import OrderManagement from './components/admin/OrderManagement';
import TokenManagement from './components/admin/TokenManagement';
import AdminSettings from './components/admin/AdminSettings';
import OrderDetails from './components/admin/OrderDetails'; // Importa a nova página de detalhes
import Reports from './components/admin/Reports';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />

            {/* Rota principal do dashboard do usuário */}
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <DashboardLayout />
                </ProtectedRoute>
              }
            >
              <Route path="buy-crypto" element={<BuyCrypto />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="transaction-history" element={<TransactionHistory />} />
              <Route path="payment-status" element={<PaymentStatus />} />
              <Route path="support" element={<Support />} />
              <Route path="account-settings" element={<AccountSettings />} />
              <Route path="logout" element={<Logout />} />
              <Route path="transaction-history" element={<TransactionHistory />} />
              <Route path="transactions/:transactionId" element={<TransactionDetails />} />
            </Route>

            {/* Rota principal do dashboard do administrador */}
            <Route 
              path="/admin" 
              element={
                <ProtectedRoute adminOnly={true}> {/* Protege a rota para administradores */}
                  <AdminDashboardLayout />
                </ProtectedRoute>
              }
            >
              <Route path="users" element={<UserManagement />} />
              <Route path="orders" element={<OrderManagement />} />
              <Route path="tokens" element={<TokenManagement />} />
              <Route path="settings" element={<AdminSettings />} />
              <Route path="orders/:orderId" element={<OrderDetails />} /> {/* Rota relativa corrigida */}
              <Route path="/admin/reports" element={<Reports />} />

            </Route>

            {/* Rota independente para o gerenciamento de pedidos */}
            <Route 
              path="/order-management" 
              element={
                <ProtectedRoute adminOnly={true}>
                  <OrderManagement />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
