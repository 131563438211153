import axios from 'axios';

const getTokenPriceFromGeckoTerminal = async () => {
  const url = 'https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0x34cb446e56386da32e0447b84ae09487a0011368';

  try {
    // Faz a requisição para obter o preço do token em USD
    const response = await axios.get(url);
    const data = response.data.data;
    const tokenPrices = data.attributes.token_prices;
    const tokenPriceUSD = parseFloat(tokenPrices['0x34cb446e56386da32e0447b84ae09487a0011368']);

    // Verifica se o preço foi convertido corretamente para número
    if (isNaN(tokenPriceUSD)) {
      throw new Error('Preço do token inválido.');
    }

    // Busca a taxa de câmbio de USD para BRL
    const exchangeRateResponse = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=brl'
    );
    const usdToBrlRate = exchangeRateResponse.data.usd.brl;

    // Converte o preço do token para BRL
    const tokenPriceBRL = tokenPriceUSD * usdToBrlRate;

    return { tokenPrice: tokenPriceBRL };
  } catch (error) {
    console.error('Erro ao buscar o preço do token:', error);
    return null;
  }
};

export default getTokenPriceFromGeckoTerminal;
