import React from 'react';
import './ServicesSection.css';

// Importar as imagens
import BitcoinImage from './img/icon-1.png';
import ExchangeImage from './img/icon-2.png';
import BankingImage from './img/icon-3.png';

const services = [
  { title: 'Transação via Pix', description: 'Inicie sua transação agora e aproveite nossa expertise em criar soluções de pagamento seguras e eficientes.', icon: BitcoinImage },
  { title: 'Troca garantida', description: 'Realize compras via PIX e receba rapídamente o criptoativo escolhido.', icon: ExchangeImage },
  { title: 'Investimentos', description: 'Facilitamos transações seguras com integração completa ao sistema Pix.', icon: BankingImage },
];

const ServicesSection = () => {
  return (
    <section className="services-section" id="services">
      <div className="services-container">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">
              {/* Exibir a imagem do serviço */}
              <img src={service.icon} alt={service.title} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
