import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './TransactionHistory.css'; // Crie um arquivo CSS para estilização

const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/transactions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTransactions(response.data);
      } catch (error) {
        setError('Erro ao buscar transações.');
        console.error('Erro ao buscar transações:', error);
      }
    };

    fetchTransactions();
  }, []);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  return (
    <div className="transaction-history-container">
      <h2>Histórico de Transações</h2>
      {error && <p className="error-message">{error}</p>}
      <table className="transaction-table">
        <thead>
          <tr>
            <th>Transação ID</th>
            <th>Status</th>
            <th>Valor</th>
            <th>Token</th>
            <th>Quantidade</th>
            <th>Preço Unitário</th>
            <th>Carteira</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction._id}>
              <td>{truncateText(transaction._id, 10)}</td>
              <td>{transaction.status}</td>
              <td>R$ {transaction.valor}</td>
              <td>{transaction.tokenSelecionado}</td>
              <td>{transaction.quantidadeTokensSelecionado}</td>
              <td>R$ {transaction.valor_unidade}</td>
              <td>{truncateText(transaction.wallet, 20)}</td>
              <td>
                <button className="view-details-button">
                  <Link to={`/dashboard/transactions/${transaction._id}`}>Detalhes</Link>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionHistory;
